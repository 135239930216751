import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { Auth, I18n } from 'aws-amplify';
import clsx from 'clsx';
import { Formik, FormikHelpers, useFormikContext } from 'formik';
import React, { FC, useContext, useEffect, useState } from 'react';
import ClientsClient from 'src/clients/ClientsClient';
import AuthContainer from 'src/components/Auth/AuthContainer';
import { AuthLinkActionButton } from 'src/components/Auth/AuthLinkActionButton';
import { ClientAuthContainer } from 'src/components/Auth/ClientAuthContainer';
import { authScreenStyles } from 'src/components/Auth/styles';
import { IAuthenticatorReturn } from 'src/components/AwsAmplify';
import Button from 'src/components/Button';
import CarouselLayout from 'src/components/Carousel/CarouselLayout';
import RowDivider from 'src/components/RowDivider';
import BaseTypography from 'src/components/Text/BaseTypography';
import { BaseTextField, PasswordField } from 'src/components/TextField';
import { GoogleAuthButton } from 'src/components/UI/Buttons/GoogleAuthButton';
import { LOGIN_PAGE } from 'src/constants';
import {
  AUTH_STATES,
  SIGNED_IN_WITH_GOOGLE_USER_KEY,
} from 'src/constants/authConsts';
import {
  AuthErrorCodes,
  AuthErrors,
} from 'src/constants/errorConsts/errorCodesConsts';
import { FlagsContext, PortalConfigContext, RouteContext } from 'src/context';
import history from 'src/history';
import { useAppDispatch } from 'src/hooks/useStore';
import { ClientFormData } from 'src/store/clients/types';
import { alertSnackbar } from 'src/store/ui/actions';
import { GraySmall } from 'src/theme/colors';
import {
  PasswordInputValidation,
  SignInWithGoogle,
  getPreferredUsername,
  logoutFromHostedUi,
} from 'src/utils/AuthUtils';
import { CognitoUserWithAttributes } from 'src/utils/CognitoUtils';
import { ensureApiError } from 'src/utils/Errors';
import { isEmptyString, removeSpaceToLowerCase } from 'src/utils/StringUtils';
import { v4 } from 'uuid';
import * as Yup from 'yup';

enum RegisterFormSteps {
  EMAIL_PASSWORD = 0,
  USER_INFO = 1,
}

enum LegacyRegisterFormSteps {
  EMAIL_PASSWORD = 1,
  USER_INFO = 0,
}

type RegisterFormValues = {
  fullName: string;
  password: string;
  companyName: string;
  email: string;
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...authScreenStyles(theme),
  }),
);

const validAuthState = ['signUp'];

const SetEmailAndPasswordForm: FC<{
  enableGoogle: boolean;
  customAuthState: string;
}> = ({ enableGoogle, customAuthState }) => {
  const { GoogleLoginForClients } = useContext(FlagsContext);

  const { handleBlur, handleChange, touched, errors, values } =
    useFormikContext<RegisterFormValues>();

  return (
    <div>
      {GoogleLoginForClients && enableGoogle && (
        <>
          <GoogleAuthButton onClick={() => SignInWithGoogle(customAuthState)}>
            Continue with Google
          </GoogleAuthButton>
          <RowDivider mt={0} mb={0}>
            <BaseTypography
              fontType="12Medium"
              style={{
                color: GraySmall,
              }}
            >
              OR
            </BaseTypography>
          </RowDivider>
        </>
      )}

      <BaseTextField
        sizeVariant={GoogleLoginForClients ? 'tall' : 'medium'}
        fullWidth
        type="text"
        key="email"
        name="email"
        variant="outlined"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.email}
        label={I18n.get('Email')}
        error={Boolean(touched.email && errors.email)}
        helperText={(touched.email && errors.email) || ' '}
        autoComplete="off"
        autoFocus={GoogleLoginForClients ? !enableGoogle : true}
      />
      <PasswordField
        sizeVariant={GoogleLoginForClients ? 'tall' : 'medium'}
        fullWidth
        type="password"
        key="password"
        name="password"
        variant="outlined"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.password}
        label={I18n.get('Password')}
        error={Boolean(touched.password && errors.password)}
        helperText={(touched.password && errors.password) || ' '}
        autoComplete="off"
        inputProps={{
          autoComplete: 'new-password',
        }}
      />
    </div>
  );
};

const SetUserInfoForm: FC<{
  areCompaniesDisabled?: boolean;
}> = ({ areCompaniesDisabled }) => {
  const { GoogleLoginForClients } = useContext(FlagsContext);
  const classes = useStyles();
  const { handleBlur, handleChange, touched, errors, values } =
    useFormikContext<RegisterFormValues>();

  const googleSignedInUser = window.localStorage.getItem(
    SIGNED_IN_WITH_GOOGLE_USER_KEY,
  );

  const isGoogleSignIn =
    googleSignedInUser != null && !isEmptyString(googleSignedInUser);

  return (
    <div>
      <BaseTextField
        sizeVariant={GoogleLoginForClients ? 'tall' : 'medium'}
        fullWidth
        type="text"
        key="fullName"
        name="fullName"
        autoFocus
        variant="outlined"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.fullName}
        label={I18n.get('Full name')}
        error={Boolean(touched.fullName && errors.fullName)}
        helperText={(touched.fullName && errors.fullName) || ' '}
        autoComplete="off"
      />
      {!areCompaniesDisabled && (
        <BaseTextField
          sizeVariant={GoogleLoginForClients ? 'tall' : 'medium'}
          fullWidth
          type="text"
          key="companyName"
          name="companyName"
          variant="outlined"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.companyName}
          autoFocus={isGoogleSignIn}
          label={
            <>
              <BaseTypography fontType="13Medium" component="span">
                {I18n.get('Company')}
              </BaseTypography>
              <BaseTypography
                component="span"
                className={classes.optionalLabelHelper}
              >
                {` (${I18n.get('Optional')})`}
              </BaseTypography>
            </>
          }
          error={Boolean(touched.companyName && errors.companyName)}
          helperText={(touched.companyName && errors.companyName) || ' '}
          autoComplete="off"
        />
      )}
    </div>
  );
};

const Register: FC<IAuthenticatorReturn> = (props) => {
  const { onStateChange, authState } = props;
  const { query } = useContext(RouteContext);
  const { action } = query;
  const portalConfig = useContext(PortalConfigContext);
  const [googleAuthClientData, setGoogleAuthClientData] = useState<
    ClientFormData | undefined
  >(undefined);
  const [showLoading, setShowLoading] = useState(false);
  const schema = Yup.object().shape({
    fullName: Yup.string()
      .trim()
      .max(64)
      .required('Full Name is required')
      .test('is-valid-name', 'Please provide a full name', (value) => {
        if (!value) return false;
        return value.split(' ').length > 1;
      }),
    companyName: Yup.string().trim().max(100),
    email: Yup.string()
      .email('Invalid email')
      .max(100)
      .required('Email is required'),
    // password validation should be omitted
    // when the user is signing up with google auth
    password: !googleAuthClientData
      ? PasswordInputValidation
      : Yup.string().nullable(),
  });
  const classes = useStyles();

  const [isSignUpLoading, setIsSignUpLoading] = useState(false);
  const [stepIndex, setStepIndex] = useState(RegisterFormSteps.EMAIL_PASSWORD);

  const handleGoToSignIn = async () => {
    const signedInWithGoogle = window.localStorage.getItem(
      SIGNED_IN_WITH_GOOGLE_USER_KEY,
    );

    // if the user is already signed in with google and
    // they are going to login flow, then we need to sign them out.
    if (signedInWithGoogle) {
      logoutFromHostedUi(
        portalConfig,
        `${portalConfig.AWS.Auth.oauth?.redirectSignOut}?app-error=signup_cancelled`,
      );
    }
    onStateChange(AUTH_STATES.SIGN_IN);
    history.push(LOGIN_PAGE);
  };

  useEffect(() => {
    onStateChange(AUTH_STATES.SIGN_UP);
    if (portalConfig.features.signupDisabled) {
      handleGoToSignIn();
    }
  }, []);

  const formikRef = React.useRef<FormikHelpers<RegisterFormValues>>(null);
  const areCompaniesDisabled = portalConfig.features.disableCompanies ?? false;
  const googleAuthFeatureEnabled = !portalConfig.features.disableGoogleSignIn;
  const { GoogleLoginForClients } = useContext(FlagsContext);
  const ContainerComponent = GoogleLoginForClients
    ? ClientAuthContainer
    : AuthContainer;

  const googleAuthState = new URLSearchParams({
    portalId: portalConfig.portalHeader,
    origin: window.location.origin,
    action: 'signup',
  });

  const dispatch = useAppDispatch();

  const completeSignup = async (clientUserData: ClientFormData) => {
    try {
      await ClientsClient.addClient(clientUserData, true);

      // if the user is signing up with google auth then we need to
      // auto sign them in.
      const signedInWithGoogle = window.localStorage.getItem(
        SIGNED_IN_WITH_GOOGLE_USER_KEY,
      );

      if (signedInWithGoogle) {
        await SignInWithGoogle(
          new URLSearchParams({
            portalId: portalConfig.portalHeader,
            origin: window.location.origin,
          }).toString(),
        );
        return true;
      }

      if (clientUserData.cognitoEmail) {
        const preferredUsername = getPreferredUsername({
          email: clientUserData.cognitoEmail,
          viewMode: portalConfig.viewMode,
          portalId: portalConfig.portalHeader,
        });

        const signInResponse = await Auth.signIn(
          preferredUsername,
          clientUserData.password,
        );

        if (signInResponse.userConfirmed) {
          onStateChange(AUTH_STATES.SIGNED_UP, {
            username: clientUserData.userId,
            password: clientUserData.password,
          });
        } else {
          // TODO: to support confirmation on sign-up we need update this item
          onStateChange(AUTH_STATES.CONFIRM_SIGN_UP, {
            challengeName: 'CONFIRM_SIGN_UP',
            username: clientUserData.userId,
            password: clientUserData.password,
            signUpPayload: signInResponse,
          });
        }
      }
      return true;
    } catch (e) {
      // when the user tries to sign up with an email that already exists
      // we need to auto sign them in with google auth
      const { message } = ensureApiError(e);
      if (
        message.includes(AuthErrorCodes.googleAccountExists) ||
        message.includes(AuthErrorCodes.accountAlreadyExists)
      ) {
        // TODO: here we call federatedSignIn in order
        // to handle the auto sign in flow. This will
        // create an extra redirect that can be avoided
        // in the future by using the handleStateChange
        // in client login flow which does the same thing.
        const signedInWithGoogle = window.localStorage.getItem(
          SIGNED_IN_WITH_GOOGLE_USER_KEY,
        );

        // auto sign in with google auth if the user is already signed in with google
        if (signedInWithGoogle) {
          await SignInWithGoogle(
            new URLSearchParams({
              portalId: portalConfig.portalHeader,
              origin: window.location.origin,
            }).toString(),
          );
        } else {
          // if the user is not trying to signup with google auth
          // this means that they are trying to sign up with an email
          // that is already in use. So we need to show an error message
          // to the user.
          dispatch(
            alertSnackbar({
              errorMessage: AuthErrors.emailAlreadyInUse,
            }),
          );
        }
      }

      setIsSignUpLoading(false);
      return false;
    }
  };

  /**
   * After user is authenticated in google hosted ui,
   * this function will grab the federated authenticated user info
   * to fill them in the register form. And it will proceed
   * to the next register form step.
   */
  const completeClientGoogleSignup = async () => {
    let user: CognitoUserWithAttributes | null = null;
    setShowLoading(true);
    try {
      user = await Auth.currentAuthenticatedUser();
    } catch (error) {
      setShowLoading(false);
      console.error('error while getting the authenticated user', error);
      return;
    }

    if (!user) {
      return;
    }
    const {
      email: cognitoEmail,
      given_name: cognitoFirstName,
      family_name: cognitoLastName,
      picture,
    } = user.attributes;

    const clientData = {
      userId: v4(),
      cognitoEmail,
      cognitoFirstName,
      cognitoLastName,
      companyName: '',
      sendInvite: false,
      noPasswordSet: true,
      externalAuthProviders: [CognitoHostedUIIdentityProvider.Google],
      avatarImageUrl: picture,
    };
    setGoogleAuthClientData(clientData);

    if (portalConfig.features.disableCompanies) {
      await completeSignup(clientData);
      return;
    }

    setShowLoading(false);
    if (!formikRef.current) {
      return;
    }
    formikRef.current.setFieldValue(
      'fullName',
      `${cognitoFirstName} ${cognitoLastName}`,
    );
    formikRef.current.setFieldValue('email', cognitoEmail);
    setShowLoading(false);
  };

  // this effect is triggered to complete the google signup flow
  // when the action query param is set to signup.
  React.useEffect(() => {
    if (action === 'signup') {
      setStepIndex(RegisterFormSteps.USER_INFO);

      completeClientGoogleSignup();
    }
  }, [action]);

  const registrationStepsForms = [
    <SetEmailAndPasswordForm
      key="email-password-step"
      enableGoogle={googleAuthFeatureEnabled}
      customAuthState={googleAuthState.toString()}
    />,
    <SetUserInfoForm
      key="user-info-step"
      areCompaniesDisabled={areCompaniesDisabled}
    />,
  ];

  const stepsForms = GoogleLoginForClients
    ? registrationStepsForms
    : registrationStepsForms.reverse();

  if (!validAuthState.includes(authState)) return null;

  // In this case, the progress bar from the parent component (ClientLogin) will be shown
  if (showLoading) return null;

  return (
    <ContainerComponent>
      <Formik
        innerRef={formikRef}
        validationSchema={schema}
        initialValues={{
          fullName: '',
          password: '',
          companyName: '',
          email: '',
        }}
        onSubmit={async (values, { setStatus }) => {
          setIsSignUpLoading(true);
          const username = v4();
          const email = removeSpaceToLowerCase(values.email);
          const givenName = values.fullName.split(' ')[0].trim();
          const familyName = values.fullName.split(' ')[1].trim();
          const companyName = values.companyName.trim();
          let clientUserData = {
            userId: username,
            cognitoEmail: email,
            cognitoFirstName: givenName,
            cognitoLastName: familyName,
            companyName,
            sendInvite: false,
            password: values.password,
          } as ClientFormData;

          // if it is a google auth user then we need to set the
          // noPasswordSet property to true and set the externalAuthProviders
          // to be google.
          clientUserData = {
            ...clientUserData,
            noPasswordSet: googleAuthClientData?.noPasswordSet ?? false,
            externalAuthProviders: googleAuthClientData?.externalAuthProviders,
            avatarImageUrl: googleAuthClientData?.avatarImageUrl,
          };

          const success = await completeSignup(clientUserData);
          if (!success) {
            setStatus(false);
          }
        }}
      >
        {({ errors, handleSubmit, values }) => {
          /**
           * This will try to validate the inputs
           * on the current step in the form to determine if the user can proceed
           * @param currentStep which page of the form the user is on
           * @returns boolean if user can proceed or not
           */
          const checkNextEnabled = (currentStep: number) => {
            if (
              currentStep ===
              (GoogleLoginForClients
                ? RegisterFormSteps.USER_INFO
                : LegacyRegisterFormSteps.USER_INFO)
            ) {
              return values.fullName && !errors.fullName && !errors.companyName;
            }

            if (
              currentStep ===
              (GoogleLoginForClients
                ? RegisterFormSteps.EMAIL_PASSWORD
                : LegacyRegisterFormSteps.EMAIL_PASSWORD)
            ) {
              // when google auth user data is defined that means
              // the user is signing up with a google account
              // so we don't need to validate the password
              if (googleAuthClientData) {
                return values.email && !errors.email;
              }
              return (
                values.email &&
                !errors.email &&
                values.password &&
                !errors.password
              );
            }

            return false;
          };

          // check both steps are step correctly
          const isSignupEnabled = checkNextEnabled(0) && checkNextEnabled(1);

          return (
            <form
              noValidate
              className={classes.formContainer}
              onSubmit={handleSubmit}
            >
              <CarouselLayout
                forceStepIndex={stepIndex}
                carouselButtonClassName={clsx({
                  [classes.submitButton]: GoogleLoginForClients,
                })}
                carouselSecondaryAction={
                  <AuthLinkActionButton
                    className={classes.navigationActionsContainerMobile}
                    onClick={handleGoToSignIn}
                    linkText="Already have an account?"
                  />
                }
                hiddenPrev
                length={2}
                nextEnabled={checkNextEnabled}
                finalActionButton={
                  <Button
                    size="large"
                    className={clsx({
                      [classes.submitButton]: GoogleLoginForClients,
                    })}
                    fullWidth
                    htmlId="register-create-account"
                    type="submit"
                    color="primary"
                    variant="contained"
                    isLoading={isSignUpLoading}
                    disabled={!isSignupEnabled}
                  >
                    {I18n.get('Sign Up')}
                  </Button>
                }
                hideIndicators={GoogleLoginForClients}
              >
                {stepsForms.map((form) => (
                  <div key={form.key}>{form}</div>
                ))}
              </CarouselLayout>
            </form>
          );
        }}
      </Formik>
      {!GoogleLoginForClients && (
        <RowDivider className={classes.divider} mt={2.5} mb={1.5} />
      )}
      <AuthLinkActionButton
        className={classes.navigationActionsContainerDesktop}
        onClick={handleGoToSignIn}
        linkText="Already have an account?"
      />
    </ContainerComponent>
  );
};

export default Register;
