import React, { useContext } from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { PortalConfigContext } from 'src/context';
import PortalLogo from 'src/components/Layout/PortalLogo';
import BaseTypography from 'src/components/Text/BaseTypography';
import { AuthScreenPortalBadge } from 'src/components/Auth/AuthScreenPortalBadge';
import { getPublicImageURL } from 'src/utils/ImageUtils';
import PaymentsClient from 'src/clients/PaymentsClient';
import { canDisablePortalBadge } from 'src/utils/PlansUtils';
import PortalFullLogo from 'src/components/Layout/PortalFullLogo';
import { GraySmall } from 'src/theme/colors';

const GOOGLE_AUTH_CARD_WIDTH = 1160;
const GOOGLE_AUTH_CARD_HEIGHT = 580;
const AUTH_CARD_WIDTH = 1000;
const AUTH_CARD_HEIGHT = 500;

type StyleProps = {
  googleAuthEnabled: boolean;
  title?: string;
};
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      maxWidth: 450,
      margin: 'auto',
    },
  },
  card: {
    boxShadow: '0px 0px 60px rgba(0, 0, 0, 0.12)',
    borderRadius: '20px',
    maxWidth: '100%',
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      width: (props: StyleProps) =>
        props.googleAuthEnabled ? GOOGLE_AUTH_CARD_WIDTH : AUTH_CARD_WIDTH,
      height: (props: StyleProps) =>
        props.googleAuthEnabled ? GOOGLE_AUTH_CARD_HEIGHT : AUTH_CARD_HEIGHT,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '100%',
      boxShadow: 'none',
      borderRadius: 0,
    },
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%',
    },
  },
  content: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      padding: (props: StyleProps) =>
        props.googleAuthEnabled
          ? theme.spacing(5, 8.75, 3.5, 8.75)
          : theme.spacing(2, 7.5, 3, 7.5),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(15, 4, 0, 4),
    },
  },
  media: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: (props: StyleProps) => (props.title ? 0 : theme.spacing(5)),
    [theme.breakpoints.down('xs')]: {
      marginBottom: (props: StyleProps) => (props.title ? 0 : theme.spacing(7)),
    },
  },
  titleContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: theme.spacing(1.5, 0, 2, 0),
    gap: 2,
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0, 3),
    },
  },
  description: {
    textAlign: 'center',
    color: GraySmall,
  },
  // avoid portal badge jumping on page load
  // by setting a min height for its container
  portalBadgeContainer: {
    minHeight: 80,
  },
}));

interface AuthContainerProps {
  title?: string;
  description?: string;
  children: React.ReactNode;
}

export const ClientAuthContainer: React.FC<AuthContainerProps> = ({
  title,
  description,
  children,
}) => {
  const portalConfig = useContext(PortalConfigContext);
  const [shouldShowPortalBadge, setShouldShowPortalBadge] =
    React.useState(false);
  const classes = useStyles({
    googleAuthEnabled: !portalConfig.features.disableGoogleSignIn,
    title,
  });
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'));

  /**
   * This method check for portal plan status. It call
   * get portal plan api which return if the portal is in pro
   * plan or not. And then, it updates the portal badge render condition
   * When it is a pro plan, get show badge flag prop from
   * the portal config, otherwise it should be explicitly shown.
   */
  const checkPortalStatus = async () => {
    const portalPlanStatusData = await PaymentsClient.getPortalPlan();
    const { isPro, isLegacyPaidPlan, planType } = portalPlanStatusData;
    const canDisableBadge = canDisablePortalBadge({
      isPro,
      isLegacyPaidPlan,
      planType,
    });
    setShouldShowPortalBadge(
      canDisableBadge ? !portalConfig.MarketingSite.disableBadge : true,
    );
  };

  React.useEffect(() => {
    checkPortalStatus();
  }, []);

  // the image height and width depends on whether google auth is enabled or not.
  const coverImageSize = portalConfig.features.disableGoogleSignIn
    ? AUTH_CARD_HEIGHT
    : GOOGLE_AUTH_CARD_HEIGHT;
  /**
   * This function provides the auth image cached url.
   * @returns auth image cdn path
   */
  const getAuthImageSrc = () => {
    const authImgS3Path = portalConfig.assets.authImage.signInUrl || '';
    return getPublicImageURL(authImgS3Path, {
      resize: {
        width: coverImageSize,
        height: coverImageSize,
      },
    });
  };

  // we need to show full logo when internal user have uploaded a file for full logo
  const showFullLogo =
    Boolean(portalConfig.assets.logo.logoImageUrl) &&
    // dummy image is used for default state (equivalent to no image uploaded)
    !portalConfig.assets.logo.logoImageUrl.includes('dummyimage');

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <div className={classes.logoWrapper}>
            {showFullLogo ? <PortalFullLogo logoHeight={60} /> : <PortalLogo />}
          </div>
          {title && (
            <div className={classes.titleContainer}>
              <BaseTypography fontType="18Medium">{title}</BaseTypography>
              {description && (
                <BaseTypography
                  fontType="13Regular"
                  className={classes.description}
                >
                  {description}
                </BaseTypography>
              )}
            </div>
          )}

          {children}
          {isMobileScreen && shouldShowPortalBadge ? (
            <AuthScreenPortalBadge />
          ) : null}
        </CardContent>
        <CardMedia
          className={classes.media}
          image={getAuthImageSrc()}
          title="Cover"
        />
      </Card>
      <div className={classes.portalBadgeContainer}>
        {!isMobileScreen && shouldShowPortalBadge ? (
          <AuthScreenPortalBadge />
        ) : null}
      </div>
    </div>
  );
};
