import React, {
  useState,
  useEffect,
  forwardRef,
  useRef,
  ChangeEvent,
  useMemo,
  useContext,
} from 'react';
import { Auth, CognitoUser } from '@aws-amplify/auth'; // eslint-disable-line
import { isEmpty, Hub, HubCapsule } from '@aws-amplify/core'; // eslint-disable-line
import { AUTH_STATES } from 'src/constants/authConsts';
import {
  AuthChildComponentData,
  AuthComponentType,
  IAuthenticatorProps,
  IAuthenticatorReturn,
  IAuthenticatorState,
  IInputsState,
} from 'src/components/AwsAmplify/common/types';
import { PortalConfigContext, RouteContext } from 'src/context';
import { ensureUnreachable, storageAvailable } from 'src/utils/CommonUtils';
import { LoginForm } from '../Auth/Login';
import { ForgotPasswordForm } from '../Auth/ForgotPassword';
import { ConfirmRegisterForm } from '../Auth/ConfirmRegister';
import { RegisterForm } from '../Auth/Register';
import { RequireNewPasswordForm } from '../Auth/RequireNewPassword';
import { VerifyMfaForm } from '../Auth/VerifyMfa';
import { VerifyMfaProps } from '../Auth/VerifyMfa/VerifyMfaForm';

function getComponentFromAuthComponentType(
  componentType: AuthComponentType,
): React.FC<IAuthenticatorReturn> | React.FC<VerifyMfaProps> {
  switch (componentType) {
    case AuthComponentType.LoginFormComponent:
      return LoginForm;
    case AuthComponentType.ForgotPasswordFormComponent:
      return ForgotPasswordForm;
    case AuthComponentType.ConfirmRegisterFormComponent:
      return ConfirmRegisterForm;
    case AuthComponentType.RegisterFormComponent:
      return RegisterForm;
    case AuthComponentType.RequireNewPasswordFormComponent:
      return RequireNewPasswordForm;
    case AuthComponentType.VerifyMfaFormComponent:
      return VerifyMfaForm;
    default:
      return ensureUnreachable(componentType);
  }
}

const AUTHENTICATOR_AUTHSTATE = 'amplify-authenticator-authState';
export const Authenticator = forwardRef<HTMLDivElement, IAuthenticatorProps>(
  (props, ref) => {
    const { authState, onStateChange, hideDefault, children } = props;
    const portalConfig = useContext(PortalConfigContext);
    const { sessionData } = useContext(RouteContext);
    const mounted = useRef(false);

    const [intitalAuthState] = useState(authState || 'signIn');

    const [authenticatorState, setAuthenticatorState] =
      useState<IAuthenticatorState>({
        authState: 'loading',
      });
    // state to store forms input values
    const [inputs, setInputs] = useState<IInputsState>({});

    // just checking if we have onStateChange function
    const changeState = (state: string, data?: CognitoUser) => {
      if (onStateChange) {
        onStateChange(state, data);
      }
    };

    // state is form type and data is auth data
    const handleStateChange = (state: string, data?: any) => {
      let tempState = state;
      if (tempState === authenticatorState.authState) {
        return;
      }

      // if user is logging out, next form will be signIn
      if (tempState === 'signedOut') {
        tempState = 'signIn';
      }

      if (storageAvailable()) {
        localStorage.setItem(AUTHENTICATOR_AUTHSTATE, tempState);
      }

      if (mounted.current) {
        setAuthenticatorState({
          authState: tempState,
          authData: data,
        });
      }
      changeState(tempState, data);
    };

    /**
     * In order to auto sign in the user who is logged in into portal A.
     * We need to make sure that the current portal session (portal A)
     * always has a valid token. This is done by calling Cognito
     * getSession() which behind the scenes will refresh the token.
     * https://github.com/aws-amplify/amplify-js/blob/272c2c607cc4adb5ddc9421444887bdb382227a0/packages/amazon-cognito-identity-js/src/CognitoUser.js#L1448
     * GetSession requires the username prop which is equal to LastAuthUser.
     * This is why we need to always sync the current portal session user id with
     * the LastAuthUser.
     *
     */
    const handleRefreshCurrentPortalSession = async () => {
      let user = null;
      const { currentPortalSession, portalIdToSession } = sessionData;

      // we need to get the current portal session related email to set it as LastAuthUser
      const { userId } = portalIdToSession[currentPortalSession] || {};

      if (userId) {
        if (storageAvailable()) {
          window.localStorage.setItem(
            `CognitoIdentityServiceProvider.${portalConfig.AWS.Auth.userPoolWebClientId}.LastAuthUser`,
            userId,
          );
        }
        // This method will automatically refresh
        // the accessToken and idToken if tokens
        // are expired and a valid refreshToken presented
        user = await Auth.currentAuthenticatedUser();
      }
      return user;
    };

    /**
     * if user is not authenticated,
     * and form type in localstorage is signedIn then logged out the user
     * and set the state to signIn
     */
    const handleNoAuthUserFound = () => {
      let cachedAuthState = null;
      if (storageAvailable()) {
        cachedAuthState = localStorage.getItem(AUTHENTICATOR_AUTHSTATE);
      }
      const promise =
        cachedAuthState === 'signedIn' ? Auth.signOut() : Promise.resolve();
      promise
        .then(() => handleStateChange(intitalAuthState))
        .catch(() => {
          throw new Error('Failed to sign out');
        });
    };

    // check if the user is authenticated
    const checkUser = async () => {
      if (!mounted.current) {
        return;
      }

      try {
        const user = await handleRefreshCurrentPortalSession();

        // if user is authenticated, set the state to signedIn
        if (user) {
          handleStateChange('signedIn', user);
          return;
        }
      } catch (error) {
        console.error('error getting auth user', error);
      }
      handleNoAuthUserFound();
    };

    // checks if the user has verified their contact info
    const checkContact = (user: CognitoUser) => {
      let tempUser = user;
      Auth.verifiedContact(tempUser).then((data) => {
        // data.verified consists of the verified contact info
        if (!isEmpty(data.verified)) {
          changeState('signedIn', user);
        } else {
          tempUser = Object.assign(user, data);
          changeState('verifyContact', user);
        }
      });
    };

    const onHubCapsule = (capsule: HubCapsule) => {
      const { channel, payload } = capsule;
      if (channel === 'auth') {
        switch (payload.event) {
          case 'cognitoHostedUI':
          case 'signIn':
            checkContact(payload.data);
            break;
          case 'cognitoHostedUI_failure':
            handleStateChange('signIn', null);
            break;
          case 'parsingUrl_failure':
            handleStateChange('signIn', null);
            break;
          case 'signOut':
            handleStateChange('signIn', null);
            break;
          case 'customGreetingSignOut':
            handleStateChange('signIn', null);
            break;
          default:
            break;
        }
      }
    };

    // on initial load checking in session if user is alreaday sign in
    useEffect(() => {
      mounted.current = true;
      checkUser();
      Hub.listen('auth', onHubCapsule);
      return () => {
        mounted.current = false;
        Hub.remove('auth', onHubCapsule);
      };
    }, []);

    const hide = useMemo(() => {
      if (!hideDefault) return [];
      return [AUTH_STATES.CONFIRM_SIGN_UP];
    }, [hideDefault]);

    // using name of the input as key saving the value in inputs state
    const handleInputChange = (evt: ChangeEvent<HTMLInputElement>) => {
      const { name, value, type, checked } = evt.target;
      const check_type = ['radio', 'checkbox'].includes(type);
      setInputs({ ...inputs, [name]: check_type ? checked : value });
    };

    let props_children: AuthChildComponentData[] = [];
    // just checking if we have childrens to render
    if (typeof children === 'object') {
      if (Array.isArray(children)) {
        props_children = children;
      }
    }

    // passing down the props to the all child components
    const render_props_children = props_children.map((child) => {
      const Component = getComponentFromAuthComponentType(child.componentType);

      return (
        <Component
          key={`aws-amplify-authenticator-props-children-${child.componentType}`}
          authState={authenticatorState.authState}
          authData={authenticatorState.authData}
          onStateChange={handleStateChange}
          inputs={inputs}
          handleInputChange={handleInputChange}
          changeState={changeState}
          checkContact={checkContact}
          checkUser={checkUser}
          hide={hide}
          user={authenticatorState.authData}
        />
      );
    });

    return <div ref={ref}>{render_props_children}</div>;
  },
);

export default Authenticator; // eslint-disable-line
